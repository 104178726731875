import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const AboutWrapper = styled.div`
  position: relative;
  padding: 90px 75px 0 75px;
  max-width: 2000px;
  margin: auto;
  @media (max-width: 990px) {
    padding: 80px 45px 30px 45px;
  }
  @media (max-width: 575px) {
    padding: 60px 25px 0 25px;
  }
`

export const SpeakerTileTitle = styled.h4`
  font-size: 19px;
  font-weight: 700;
  color: hsl(0, 0%, 16%);
  font-family: 'Fira Sans', sans-serif;
  margin: 28px 0 15px 0;
`
export const SpeakerTileIcons = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 10px 0;
`

export const AboutPageTitle = styled.div`
  margin-bottom: 45px;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    color: ${themeGet('colors.textColor', '#292929')};
    line-height: 1.53;
    margin-bottom: 10px;
    @media (max-width: 990px) {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`

export const SpeakerTileImg = styled.img`
  height: 300px;
  margin: auto;
  display: block;
  @media (max-width: 500px) {
    height: 100px;
  }
`

export const SpeakerTileA = styled.a`
  cursor: pointer;
  background-color: hsl(0, 0%, 16%);
  width: auto;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  color: hsl(0, 0%, 100%);
  width: 100%;
  display: block;
  text-align: center;
  padding: 11px 27px;

  &:hover {
    box-shadow: 0px 10px 20px 4px #00000035;
  }
`

export const SpeakerTileClock = styled.img`
  height: 20px;
  margin-right: 8px;
`

export const SpeakerIcon = styled.img`
  position: absolute;
  height: 60px;
  top: -19px;
  left: -19px;

  @media (max-width: 500px) {
    height: 40px;
  }
`

export const SpeakerTile = styled.div`
  width: 45%;
  box-shadow: 0px 10px 20px 4px #00000015;
  padding: 50px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 75px;

  @media (max-width: 1100px) {
    width: 100%;
  }
`

export const AboutDetails = styled.div`
  width: 870px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 21px;
    font-weight: 500;
    color: ${themeGet('colors.textColor', '#292929')};
    margin-bottom: 40px;
    @media (max-width: 990px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }
`
export const SocialProfiles = styled.div`
  margin-top: 60px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 40px;
  }

  &:before {
    content: '';
    width: 30px;
    height: 1px;
    background: #292929;
    display: block;
    margin: auto;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`
