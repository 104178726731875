import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Courses from '../containers/Courses'

type AboutPageProps = {}

const AboutPage: React.FunctionComponent<AboutPageProps> = props => {
  return (
    <Layout>
      <SEO
        title="Courses"
        description="I've given courses on topics that range from JavaScript to SQL"
      />

      <Courses />
    </Layout>
  )
}

export default AboutPage
