import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import StarRatingComponent from 'react-star-rating-component'
import SocialProfile from '../../../components/SocialProfile/SocialProfile'
import { IoLogoTwitter, IoLogoGithub, IoLogoLinkedin } from 'react-icons/io'
import {
  AboutWrapper,
  SpeakerTile,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
  SpeakerTileA,
  SpeakerTileImg,
  SpeakerTileTitle,
  SpeakerTileIcons,
  SpeakerIcon,
  SpeakerTileClock
} from './style'
import Foundation from './images/foundation.png'
import CSS from './images/css.png'
import SQL from './images/sql.png'
import AdvancedSQL from './images/advanced-sql.png'
import Algorithms from './images/algorithms.png'
import Inheritance from './images/oo.png'
import Enzyme from './images/enzyme.png'
import Puppeteer from './images/puppeteer.png'
import Saga from './images/saga.png'
import JS from './images/js.png'
import PG from './images/pg.png'
import CSSIcon from './images/cssIcon.png'
import react from './images/react.png'
import jest from './images/jest.png'
import redux from './images/redux.png'
import clock from './images/clock.svg'

const SocialLinks = [
  {
    icon: <IoLogoGithub />,
    url: 'https://github.com/twclark0/',
    tooltip: 'Github'
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/iamtylerwclark',
    tooltip: 'Twitter'
  },
  {
    icon: <IoLogoLinkedin />,
    url: 'https://www.linkedin.com/in/tyler-clark-80003569/',
    tooltip: 'Linked In'
  }
]

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = props => {
  const courses = [
    {
      title: 'Advanced SQL for Professional Developers',
      time: '44m',
      link:
        'https://egghead.io/courses/advanced-sql-for-professional-developers?af=byvcaq',
      description: `You've got some practice with using SQL to select, update, and join database tables. But perhaps you've found yourself with a sneaking suspicion that you could be more efficient with your PostgreSQL. Topics in this course include working with CSV files, different techniques for aggregating & filtering, transactions, common table expressions, variables, creating new types, and more!`,
      reviews: 4.6,
      img: AdvancedSQL,
      icon: PG
    },
    {
      title: 'Advanced JavaScript Foundations',
      time: '41m',
      link:
        'https://egghead.io/courses/advanced-javascript-foundations?af=byvcaq',
      description: `It all starts with syntax. From there, once you’ve got the concepts down, you can move into using a framework where you figure out how to make things “just work”. Everything is great, until it’s not. If someone put you on the spot right now and asked you to explain this, would they like your answer? What about prototypal inheritance or coercion? In this course, you’ll be taking things to the next level, shining a light into “black-box” concepts like prototypes, the this keyword, and what primitive types are. I'll walk you through each concept, and build your understanding through examples in the areas you need to know.`,
      reviews: 4.5,
      img: Foundation,
      icon: JS
    },
    {
      title: 'SQL Fundamentals',
      time: '33m',
      link: 'https://egghead.io/courses/sql-fundamentals?af=byvcaq',
      description: `Most applications rely on databases to store data. Understanding how to create a database and communicate with it unlocks a critical power in your web development career. In this course, I present all the building blocks of creating and interacting with a PostgreSQL database. From creating a brand new table, up through organizing and aggregating data across multiple tables, you will learn the fundamental skills of SQL — and you’ll be prepared to apply them right away. After completing this course, you’ll be ready to tackle more challenging SQL topics like proper data modeling, normalizing data, and exploring different database types.`,
      reviews: 4.2,
      img: SQL,
      icon: PG
    },
    {
      title: 'CSS Fundamentals',
      link: 'https://egghead.io/courses/css-fundamentals?af=byvcaq',
      description: `Can you center an HTML element? Do you know how the box model works? CSS is a fundamental language imperative to working with websites. It controls all the visual aspects of a site, from colors to layouts. Even the most experienced developer can learn something new when it comes to using and understanding how the browser interprets CSS. In this course, we will slowly style a website according to a mocked image.`,
      reviews: 4.3,
      img: CSS,
      icon: CSSIcon,
      time: '33m'
    },
    {
      title: 'Algorithms in JavaScript',
      link: 'https://egghead.io/courses/algorithms-in-javascript?af=byvcaq',
      description: `Algorithms are functions that are used to solve a class of problems. Really any piece of code can be a algorithm! There are common algorithm implementations that have proven to accomplish tasks faster than others. However, speed is not measured in seconds but in terms of growth (Big O notation). This course is designed to teach not only some of those algorithmic approaches to everyday problems but review the pitfalls and optimizations that comes with using them at all. They can be a dynamic and powerful way to optimize calculations, processes, and performing tasks. However, if used incorrectly it can not only destroy a system but can be a nightmare to untangle. Many programming positions today test the knowledge of an individual’s ability to understand and implement algorithms. Let’s work through recursion, sorting, loops, and many more!`,
      reviews: 4.2,
      img: Algorithms,
      icon: JS,
      time: '42m'
    },
    {
      title: `Understanding JavaScript's Prototypal Inheritance`,
      link:
        'https://egghead.io/courses/understanding-javascript-s-prototypal-inheritance?af=byvcaq',
      description: `You might not think this question applies to you, especially if you come from a classical background. But if you use objects, functions, or arrays, you're probably already using prototypes, and can benefit from learning the logic behind them. This course explores the mechanics of inheritance in JavaScript by showing how prototypes work as chained references to other objects. Learn to take full advantage of prototype shortcuts to make your work simpler and faster…and debug them if you hit a snag.`,
      reviews: 4.2,
      img: Inheritance,
      icon: JS,
      time: '27m'
    },
    {
      title: `Test React Components with Enzyme and Jest`,
      link:
        'https://egghead.io/courses/test-react-components-with-enzyme-and-jest?af=byvcaq',
      description: `In this course we are going to work through properly setting up Enzyme with Jest to test rendered components. This includes the nitty gritty of making our testing environment work with various versions of React. After we get our environment setup ready, we’ll work through the different ways we can render a component within our test pages. With a rendered component we can now test component methods and properties to assert that they are what is intended.`,
      reviews: 4.4,
      img: Enzyme,
      icon: react,
      time: '41m'
    },
    {
      title: `End to End Testing with Google's Puppeteer and Jest`,
      link:
        'https://egghead.io/courses/end-to-end-testing-with-google-s-puppeteer-and-jest?af=byvcaq',
      description: `End-to-end testing assures you of a few things: that all the integrated pieces of an application properly function, all those pieces work together as expected, and your tests include and simulate real-user scenarios — something unit and integration tests just don't do. Over 9 lessons, we will create common application features and use Puppeteer and Jest to run different types of tests against them. You'll get practice using these tools to render content, mimic user activity, automate tests, and measure the overall performance of your application.`,
      reviews: 4.4,
      img: Puppeteer,
      icon: jest,
      time: '34m'
    },
    {
      title: `Async React with Redux Saga`,
      link: 'https://egghead.io/courses/async-react-with-redux-saga?af=byvcaq',
      description: `In this course we are going to take an empty React and Redux application and add Redux Saga side effects. We’ll begin by installing saga to the app and implement a basic saga generator function. We’ll explore the saga commands provided by Redux Saga and what the difference is between blocking and non-blocking actions. We will also understand how to concurrently run sagas with saga helpers and how we wire everything up with the Redux store. Finally, we’ll finish up by implementing tests for our sagas.`,
      reviews: 4.0,
      img: Saga,
      icon: redux,
      time: '26m'
    }
  ]

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>Courses</h2>
        <p>No "fluff" video screencasts</p>
      </AboutPageTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        {courses.map((s, i) => (
          <SpeakerTile key={i}>
            <SpeakerIcon src={s.icon} alt="course icon" />
            <div>
              <div>
                <SpeakerTileImg src={s.img} alt="course artwork" />
              </div>
              <SpeakerTileTitle>{s.title}</SpeakerTileTitle>
              <SpeakerTileIcons>
                <StarRatingComponent
                  name="rate2"
                  editing={false}
                  starCount={5}
                  value={s.reviews}
                />
                {s.reviews}
              </SpeakerTileIcons>
              <div style={{ display: 'flex', marginBottom: '10px' }}>
                <SpeakerTileClock src={clock} />
                {s.time}
              </div>
              <p>{s.description}</p>
            </div>
            <SpeakerTileA href={s.link} target="_blank">
              Check it out
            </SpeakerTileA>
          </SpeakerTile>
        ))}
      </div>
      <AboutDetails>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
